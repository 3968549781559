import React from "react";
import { TextField } from "@mui/material";
import formStore from "../../../store/Form.store";
import { observer } from "mobx-react-lite";

const ContactInput = observer(({sx}) => {

    const handleChangeContact = (text) => {
        formStore.setContactText(text)
        formStore.setContactError(false)
    }

    return (
        <TextField
            variant="standard"
            size="small"
            label="Телефон или email"
            required
            fullWidth
            error={formStore.getContactError()}
            sx={{
                maxWidth: { xs: "auto", md: 300 },
                minWidth: 200,
                ...sx
            }}
            onChange={(e) => handleChangeContact(e.target.value)}
        />
    )
})

export default ContactInput