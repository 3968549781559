import React from "react";
import { Box } from "@mui/material";

const FilledCircle = ({ size=100, bgcolor="primary.main", border=false, borderColor="primary.main", sx}) => {

    return (
        <Box
            sx={{
                width: size,
                height: size,
                bgcolor: bgcolor,
                borderRadius: "100%",
                border: border ? "1px solid" : "none",
                borderColor: borderColor,
                ...sx
            }} />
    )
}

export default FilledCircle