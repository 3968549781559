import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { provider } from "react-ioc";
import { useInstance } from "react-ioc";
import CaruselStore from "../store/Carusel.store";
import { observer } from "mobx-react";
import ImgComponent from "./ImgComponent";
import TextComponent from "./TextComponent";

const Frame = observer(() => {
    const store = useInstance(CaruselStore)

    return (
        <Grid container spacing={4} sx={{ minHeight: "560px" }}>
            <Grid
                item
                xs={12}
                md={4}
                sx={{order: {xs: 1, md: 0}}}
            >
                <TextComponent />
            </Grid>
            {store.getActiveFrame()?.img &&
                <Grid item xs={12} md={8} sx={{display: "flex", alignItems: "center", order: {xs: 0, md: 1}}}>
                    <ImgComponent />
                </Grid>
            }
        </Grid>
    )
})

export default Frame