import React from "react";
import { Box, Typography } from "@mui/material";
import formStore from "../../../store/Form.store";
import { observer } from "mobx-react-lite";


const Sended = observer(() => {

    const textOutput = (title, text) => {
        return (
            <Box sx={{mt: 2}}>
                <Typography variant="h4" sx={{ fontSize: "1.2rem", fontWeight: 300 }}>
                    {title}
                </Typography>
                <Typography sx={{ whiteSpace: "pre-wrap", mt: 1 }}>
                    {text}
                </Typography>
            </Box>
        )
    }

    return (
        <>
            <Typography variant="h3" sx={{ fontSize: "2rem", fontWeight: 200 }}>
                Ваше сообщение отправлено
            </Typography>
            <Box sx={{mt: 2}}>
                {textOutput("Телефон или email:", formStore.getContactText())}
                {textOutput("Ваше имя:", formStore.getNameText())}
                {textOutput("Текст сообщения:", formStore.getTextText())}
            </Box>
        </>
    )
})

export default Sended