import React from "react";
import { Box } from "@mui/material";
import formStore from "../../store/Form.store";
import { observer } from "mobx-react-lite";
import Form from "./components/Form";
import Sended from "./components/Sended";

const SendForm = observer(() => {

    return (
        <Box
            sx={{
                width: { xs: "100%", md: 400, lg: 500 },
                pl: 4,
                pr: 4,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: {xs: "start", md: formStore.getSended() ? "start" : "end"},
                order: formStore.getSended() ? 2 : 0
            }}>
            {formStore.getSended() ?
                <>
                    <Sended />
                </>

                :
                <>
                    <Form />
                </>

            }
        </Box>
    )
})

export default SendForm