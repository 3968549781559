import React from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import RoundBtnStore from "../store/store";
import { useInstance } from "react-ioc";

const BackgroundCircle = observer(({size="100%"}) => {
    const store = useInstance(RoundBtnStore)

    const darkCircle = {
        transition: store.getSpeed(),
        transform: store.getHover() ? "translate(-50%, -50%) rotate(60deg)" : "translate(-50%, -50%) rotate(0deg)",
        borderRadius: "100%",
        borderTop: "1px solid",
        borderRight: "1px solid",
        borderColor: store.getHover() ? "primary.contrast" : "black"
    }

    const lightCircle = {
        transition: store.getSpeed(),
        borderRadius: "100%",
        borderBottom: "1px solid",
        borderLeft: "1px solid",
        borderColor: store.getHover() ? "black" : "primary.contrast",
        opacity: store.getHover() ? 1 : 0.2
    }

    return (
        <Box
            sx={{
                width: size,
                height: size,
                position: "absolute",
                left: "50%",
                top: "50%",
                ...darkCircle
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    ...lightCircle
                }}
            >
            </Box>
        </Box>
    )
})

export default BackgroundCircle