import React from "react";
import { Box } from "@mui/material";

const SectionWrap = ({children, sectionId="#", sx}) => {

    return(
        <Box sx={{ minHeight: "100vh", ...sx }} id={sectionId}>
            {children}
        </Box>
    )
}

export default SectionWrap