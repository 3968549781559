import {
    makeAutoObservable,
    toJS
} from "mobx"

export default class CaruselStore {
    activeFrameIndex = 0
    frames = [

    ]

    setFrames (frames) {
        this.frames = frames
    }

    setActiveFrameIndex (index) {
        this.activeFrameIndex = index
    }

    getActiveFrame () {
        return this.frames[this.activeFrameIndex]
    }

    getActiveFrameIndex() { 
        return this.activeFrameIndex
    }

    getFrames() {
        return this.frames
    }

    getLength() {
        return this.frames?.length
    }

    constructor(){
        makeAutoObservable(this)
    }
}