import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, List, ListItem, ListItemIcon } from "@mui/material";
import listSign from "../../../img/icon/listItem.svg"

const SpaceListItem = ({title, index}) => {
    const [hover, setHover] = useState(false)
    const rotationStep = 30

    const getRotation = () => {
        return `scale(1) rotate(${rotationStep * (index + 1)}deg)`
    }

    const getHoverRotation = () => {
        return `scale(1.6) rotate(${rotationStep * (index + 4)}deg)`
    }

    const handleSetHover = () => {
        setHover(true)
    }

    const handleRemoverHover = () => {
        setHover(false)
    }

    return (
        <ListItem onMouseEnter={handleSetHover} onMouseLeave={handleRemoverHover}>
            <ListItemIcon>
                <img
                    src={listSign}
                    style={{
                        width: "24px",
                        transition: "0.6s",
                        transform: hover ? getHoverRotation() : getRotation(),
                    }}
                />
            </ListItemIcon>
            <Typography sx={{ color: "white", fontWeight: hover && 300 }}> {title} </Typography>
        </ListItem>
    )

}

export default SpaceListItem