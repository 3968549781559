import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import RoundBtnStore from "./store/store";
import { useInstance, provider } from "react-ioc";
import BackgroundCircle from "./components/BackgroundCircle";
import BtnContent from "./components/BtnContent";
import FilledCircle from "../geometry/FilledCircle";
import SmallCircles from "./components/SmallCircles";

//there are 3 types connected with svg figures
//types:
//cube, sphere, pyramid

const RoundBtn = provider(RoundBtnStore)(observer(({ type = "pyramid", title = "project", speed="0.8s", rotation=null, randomRotation=false, onClick }) => {
    const store = useInstance(RoundBtnStore)

    useEffect(()=>{
        store.setSpeed(speed)
        store.setType(type)
        store.setTitle(title)
        if (rotation){
            store.setInitialRotation(rotation)
        }
        if (randomRotation){
            store.setRandomInitialRotation()
        }
    },[title])

    const handleClick = (e) => {
        onClick && onClick(e)
    }

    const handleMouseEnter = () => {
        store.setHover(true)
    }

    const handleMouseLeave = () => {
        store.setHover(false)
    }

    const circlePos = () => {
        switch (store.getInitialRotation()) {
            case 0:
                return { top: "auto", right: "0%" }
            case 1:
                return { bottom: "8%", right: "8%" }
            case 2:
                return { top: "4%", right: "4%" }
            case 3:
                return { bottom: "8%", left: "8%" }
            default:
                return { top: "auto", right: "0%" }
        }
    }

    return (
        <button
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                background: "transparent",
                border: "none",
                outline: "none"
            }}
        >
            <Box
                sx={{
                    width: 400,
                    height: 400,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                <BackgroundCircle size="100%"/>
                <BackgroundCircle size="70%"/>
                <BtnContent/>
                <Box 
                sx={{
                    position: "absolute",
                    ...circlePos()
                    }}>
                    <FilledCircle size={300} border={true} bgcolor="primary.contrast005" borderColor="primary.contrast01"/>
                </Box>
                <SmallCircles/>
            </Box>
        </button>

    )
}))

export default RoundBtn