import {
    makeAutoObservable
} from "mobx"

export default class RoundBtnStore {

    rotationVariants = [0, 1, 2, 3]
    initialRotation = null

    setInitialRotation(value) {
        this.initialRotation = value
    }

    setRandomInitialRotation() {
        this.setInitialRotation(this.rotationVariants[Math.floor(Math.random() * this.rotationVariants.length)])
    }

    getInitialRotation() {
        return this.initialRotation
    }

    hover = false
    type = "cube"
    title = "project"
    speed = "0.5s"

    getHover() {
        return this.hover
    }

    setHover(value) {
        this.hover = value
    }

    getType() {
        return this.type
    }

    setType(type) {
        this.type = type
    }

    getTitle() {
        return this.title
    }

    setTitle(title) {
        this.title = title
    }

    getSpeed() {
        return this.speed
    }

    setSpeed(speed) {
        this.speed = speed
    }

    constructor() {
        makeAutoObservable(this)
    }
}