import React from "react";
import { Checkbox, TextField, FormControlLabel, Button, Box } from "@mui/material";
import formStore from "../../../store/Form.store";
import { observer } from "mobx-react-lite";
import ContactInput from "./ContactInput";
import NameInput from "./NameInput";

const Form = observer(() => {

    const handleSend = () => {
        formStore.send().then((data) => {
            if (data) {
                formStore.setSended(true)
            }
        })
    }

    const handleChangeText = (text) => {
        formStore.setTextText(text)
        formStore.setTextError(false)
    }

    const handleCheck = () => {
        formStore.switchChecked()
    }

    return (
        <>
            <Box
                sx={{
                    display: { xs: "flex", md: "none" },
                    gap: 2,
                    flexWrap: { xs: "wrap", sm: "nowrap" },
                    width: "100%"
                }}>
                <ContactInput />
                <NameInput />
            </Box>
            <ContactInput sx={{ display: { xs: "none", md: "block" } }} />
            <NameInput sx={{ display: { xs: "none", md: "block" } }} />
            <TextField
                size="small"
                variant="outlined"
                label="Опишите ваш проект"
                required
                error={formStore.getTextError()}
                sx={{
                    mt: 2,
                }}
                fullWidth
                multiline
                minRows={4}
                maxRows={8}
                inputProps={{ maxLength: 1000 }}
                onChange={(e) => handleChangeText(e.target.value)}
                helperText={"Не более 1000 символов"}
            />
            <FormControlLabel onChange={handleCheck} control={<Checkbox />} label="Я согласен с политикой конфиденциальности" />
            <Button
                variant="contained"
                onClick={handleSend}
                disabled={formStore.getDisableBtn()}
                fullWidth
            >
                {formStore.getSendBtnName()}
            </Button>
        </>
    )
})

export default Form