import { Typography, Box, Link } from "@mui/material";
import React from "react";
import SectionLinks from "./components/SectionLinks";
import EmailLink from "../../assets/UI/text/EmailLink";

const Footer = () => {

    const SectionLink = (title, id) => {
        const clickLink = (e) => {
            e.preventDefault()
            const targetElement = document.getElementById(id)
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
        return (
            <Link href={`#${id}`} onClick={clickLink}>
                {title}
            </Link>
        )
    }

    return (
        <Box sx={{ p: 4, bgcolor: "#000000", color: "white" }}>
            <Box sx={{ p: 4, pl: 8 }}>
                <SectionLinks />
                <Typography sx={{ textDecoration: "underline" }}>
                    Не является публичной офертой
                </Typography>
                <Box>
                    <EmailLink color="inherit"/>
                </Box>
            </Box>
        </Box>
    )
}

export default Footer;