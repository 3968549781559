import React from "react";
import SectionTitle from "../../assets/UI/text/SectionTitle";
import SectionWrap from "../../assets/UI/SectionWrap"
import { portfolioId } from "../../constants/sectionIds";
import { theme } from "../../theme/Theme";
import system from "../../assets/img/system.svg"
import Btns from "./components/Btns";
import { provider } from "react-ioc";
import PortfolioStore from "./store/portfolio.store";
import PortfolioDetails from "./components/PortfolioDeteils";

const Portfolio = provider(PortfolioStore)(() => {

    return (
        <SectionWrap sectionId={portfolioId} sx={{ background: theme.palette.background.darkBg, pt: 10, overflow: "hidden", position: "relative" }}>
            <img
                src={system}
                alt="Your SVG"
                style={{
                    width: "50vw",
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    transform: "translateY(40%)",
                    opacity: 0.05,
                }}
            />
            <SectionTitle sx={{ color: "text.contrast" }}>
                Наши проекты
            </SectionTitle>
            <Btns/>
            <PortfolioDetails/>
        </SectionWrap>
    )
})

export default Portfolio