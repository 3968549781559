import React, { useEffect, useState } from "react";
import Arrow from "../arrow/Arrow";

const ArrowBtn = ({ size, direction, animationSpeed,  onClick }) => {
    const [hover, setHover] = useState(false)

    const handleClick = (e) => {
        onClick && onClick(e)
    }

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    return (
        <button
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                background: "transparent",
                border: "none",
                outline: "none",
                transition: '0.2s',
                opacity: hover ? 0.6 : 1,
                cursor: "pointer"
            }}
        >
            <Arrow size={size} direction={direction} animationSpeed={animationSpeed}/>
        </button>
    )
}

export default ArrowBtn