import {
    makeAutoObservable,
    toJS
} from "mobx"
import { project1Id, project2Id, project3Id } from "./projects"

export default class PortfolioStore {

    state = {
        btnsRotations: [0, 1, 2, 3],
        activeBtnId: null,
        rotationCounted: false,
        btns: [
            {
                id: project1Id,
                type:"cube",
                title:"CRM система",
                rotation: null,
                speed: "0.8s"
            },
            {
                id: project2Id,
                type:"sphere",
                title:"Лендинг",
                rotation: null,
                speed: "0.8s"
            },
            {
                id: project3Id,
                type:"pyramid",
                title:"MLM платформа",
                rotation: null,
                speed: "0.8s"
            }
        ]
    }

    //get btns

    getBtns () {
        return this.state.btns
    }

    //set / get rotationCounted

    getRotationCounted() {
        return this.state.rotationCounted
    }

    setRotationCounted(value) {
        this.state.rotationCounted = value
    }

    //shufl btns fotations

    removeBtnRot(rotationRemove) {
        this.state.btnsRotations = this.state.btnsRotations.filter(rotation => rotation !== rotationRemove)
    }

    changeBtnRot(btnIndex, rotation) {
        this.state.btns = this.state.btns.map((btn, i) => i === btnIndex ? { ...btn, rotation: rotation } : btn )
    }

    shuffleBtnsRotation(btnIndex) {
        const randomIndex = Math.floor(Math.random() * this.state.btnsRotations.length)
        const randomNum = this.state.btnsRotations[randomIndex]
        this.changeBtnRot(btnIndex, randomNum)
        this.removeBtnRot(randomNum)
        return randomNum
    }

    setBtnsRotationsRandom() {
        for (let i = 0; i < this.state.btns.length; i++){
            this.shuffleBtnsRotation(i)
        }
        this.setRotationCounted(true)
    }

    //get / set active btnId

    getActiveBtnId() {
        return this.state.activeBtnId
    }

    setActiveBtnId(id) {
        this.state.activeBtnId = id
    }

    constructor(){
        this.setBtnsRotationsRandom()
        makeAutoObservable(this)
    }

}