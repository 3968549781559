import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CaruselStore from "../store/Carusel.store";
import { useInstance } from "react-ioc";
import { observer } from "mobx-react";
import ArrowBtn from "../../arrowBtn/ArrowBtn";
import ImgComponent from "./ImgComponent";
import TextComponent from "./TextComponent";

const FrameWithNav = observer(() => {
    const store = useInstance(CaruselStore)

    const handleIncCaruselClick = () => {
        const next = (store.getActiveFrameIndex() + 1 !== store.getLength()) ? (store.getActiveFrameIndex() + 1) : 0
        store.setActiveFrameIndex(next)
    }

    const handleDecCaruselClick = () => {
        const prev = (store.getActiveFrameIndex() !== 0) ? (store.getActiveFrameIndex() - 1) : store.getLength() - 1
        store.setActiveFrameIndex(prev)
    }

    return (
        <Box sx={{minHeight: 600}}>
            <Box sx={{ display: "flex", width: "100%", pb: 4 }}>
                <ArrowBtn direction={"left"} onClick={handleDecCaruselClick} />
                <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: "white", fontWeight: 400, textAlign: "center" }} variant="h4">
                        {store.getActiveFrame()?.title}
                    </Typography>
                </Box>
                <ArrowBtn direction={"right"} onClick={handleIncCaruselClick} />
            </Box>
            {store.getActiveFrame()?.img &&
                <ImgComponent />
            }
            <Box sx={{mt: 4}}>
                <TextComponent showTitle={false} />
            </Box>
        </Box>

    )
})

export default FrameWithNav