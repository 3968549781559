import React from "react";
import { Box, Divider } from "@mui/material"
import { observer } from "mobx-react";
import Spiral from "../../CustomAnimation/Spiral/Spiral";

const SpiralDivider = observer(({size=30, time=2, color, sx, props}) => {

    return (

        <Divider orientation="vertical" flexItem {...props} sx={{...sx}}>
            <Box sx={{ width: size }}>
                <Spiral size={size} time={time} color={color}/>
            </Box>
        </Divider>

    )
})

export default SpiralDivider