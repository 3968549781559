import React, { useEffect } from "react";
import { Box } from "@mui/material";

const Spiral = ({ size = 100, color = "primary.main", time = 3 }) => {

    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        const keyframesTop =
            `@keyframes animate-top {
                0% { width: ${size}px; border-left: none;  border-right: 1px solid;}
                25% { width: 1px; }
                50% { width: ${size}px; border-left: 1px solid; border-right: none; }
                75% { width: 1px; }
                100% { width: ${size}px; border-left: none; border-right: 1px solid;}
            }
            `
        const keyframesBottom = `
            @keyframes animate-bottom {
                0% { width: ${size}px; border-left: 1px solid;  border-right: none;}
                25% { width: 1px; }
                50% { width: ${size}px; border-left: none; border-right: 1px solid; }
                75% { width: 1px; }
                100% { width: ${size}px; border-left: 1px solid; border-right: none;}
            }`
        styleSheet.insertRule(keyframesTop, styleSheet.cssRules.length)
        styleSheet.insertRule(keyframesBottom, styleSheet.cssRules.length)
    }, [])

    const BoxBaseSx = {
        // bgcolor: "black",
        width: size,
        height: size,
        borderRadius: "100%",
        bordeColor: color,
        animationDuration: `${time}s`,
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ ...BoxBaseSx, animationName: "animate-top" }} />
            <Box sx={{ ...BoxBaseSx, borderRight: "1px solid", animationName: "animate-bottom" }} />
        </Box>
    )
}

export default Spiral