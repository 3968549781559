import { Typography } from "@mui/material";
import React from "react";
import { provider } from "react-ioc";
import Carusel from "../../../../assets/UI/carusel/Carusel";
import { landingFrames } from "./store/LandingData";

const Landing = provider()(() => {

    return (
        <>
           <Carusel frames={landingFrames} />
        </>
    )
})

export default Landing