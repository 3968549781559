import React from "react";
import { Link } from "@mui/material";

const CustomLink = ({href="#", onClick, color="inherit", text="", sx, ...props}) => {
    
    const handleClick = (e) => {
        onClick && onClick(e)
    }

    return (
        <Link href={href} onClick={handleClick} underline="hover" color={color} sx={{sx}} {...props}>
            {text}
        </Link>
    )
}

export default CustomLink