import { Box, Typography } from "@mui/material"
import { useState } from "react"
import BtnSkin from "./btnSkin/BtnSkin"

const SpaceBtn = ({ color = "primary.contrast", title, speed, onClick }) => {
    
    return (
        <button style={{ border: "none", background: "transparent", outline: "none" }} onClick={onClick && onClick}>
            <Box sx={{ position: "relative", width: 300 }}>
                <Typography sx={{ color: color, fontWeight: 300 }}>
                    {title}
                </Typography>
                <BtnSkin speed={speed}/>
            </Box>
        </button>
    )
}

export default SpaceBtn