import React from 'react';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';

export const Theme = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      {children}
    </ThemeProvider>
  );
};


export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920
    }
  },
  components: {
    borderColor: '#ECEDFF',
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
          fontWeight: 200,
        }
      }
    },
    MuiLocalizationProvider: {
      defaultProps: {
        localeText: {
          cancelButtonLabel: 'Отмена',
        },
      },
    },

    //styles for menu items - inspect select mui dropdown
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&[role="option"]': {
              fontSize:"16px",
              fontWeight:"700",
              lineHeight: "28px",
              color: "black",
              opacity: "1",
          },
        },
      },
    },


    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          "& .MuiAutocomplete-option": {
            fontSize:"16px !important",
            fontWeight:"700 !important",
            lineHeight: "28px !important",
            color: "black !important",
            opacity: "1",
          }
        },
      }
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        sizeSmall: {
          padding: '6px 16px'
        },
        sizeMedium: {
          padding: '8px 20px'
        },
        sizeLarge: {
          padding: '11px 24px'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px'
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        },
        subheaderTypographyProps: {
          variant: 'body2'
        }
      },
      styleOverrides: {
        root: {
          padding: '32px 24px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#E6E8F0'
        }
      }
    },
  },
  palette: {
    neutral: {
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827'
    },
    action: {
      active: '#6B7280',
      focus: 'rgba(55, 65, 81, 0.12)',
      hover: 'rgba(236, 237, 255, 1)',
      selected: 'rgba(55, 65, 81, 0.08)',
      disabledBackground: 'rgba(55, 65, 81, 0.12)',
      disabled: 'rgba(55, 65, 81, 0.26)'
    },
    background: {
      default: '#F3F4F9',
      paper: '#FFFFFF',
      darkBg: 'linear-gradient(135deg, #1E1B29 0%, #000000 50%, #080028 100%)'
    },
    divider: '#28233E',
    primary: {
      main: '#28233E',
      contrast: '#FFF8F3',
      contrast005: 'rgba(255, 248, 243, 0.05)',
      contrast01: 'rgba(255, 248, 243, 0.1)',
    },
    secondary: {
      main: '#10B981',
      light: '#9499d7',
      dark: '#54599b',
      contrastText: '#FFFFFF'
    },
    contrastLight: {
      main: "#FFFFFF",
    },
    success: {
      main: '#14B8A6',
      light: '#43C6B7',
      dark: '#0E8074',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#9499d7',
      light: '#CDD0FA',
      dark: '#7F85D3FF',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#FFB020',
    },
    error: {
      main: '#BD3333',
    },
    text: {
      primary: '#0B0A0E',
      contrast: '#ECEAF4',
      secondary: '#65748B',
      disabled: 'rgba(55, 65, 81, 0.48)'
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    button: {
      fontWeight: 200
    },
    fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontSize: '1rem',
      fontWeight: 200,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 200,
      lineHeight: 1.57
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 200,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 200,
      lineHeight: 1.57
    },
    subtitle3: {
      fontSize: '1rem',
      fontWeight: 100,
      lineHeight: 1.75
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 200,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 200,
      lineHeight: 1.66
    },
    h1: {
      fontWeight: 100,
      fontSize: '3.5rem',
      lineHeight: 1.375,
    },
    h2: {
      fontWeight: 100,
      fontSize: '3rem',
      lineHeight: 1.375
    },
    h3: {
      fontWeight: 200,
      fontSize: '2.25rem',
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 200,
      fontSize: '2rem',
      lineHeight: 1.375
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.375
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.375
    },
    h7: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1
    }
  }
});