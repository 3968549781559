import React from "react";
import System from "../../assets/CustomAnimation/System/System";
import { Typography, Box } from "@mui/material";
import { aboutUsId } from "../../constants/sectionIds";
import SectionTitle from "../../assets/UI/text/SectionTitle";
import SectionWrap from "../../assets/UI/SectionWrap";

const AboutUs = () => {
    return (
        <SectionWrap sectionId={aboutUsId} sx={{ position: "relative", overflow: "hidden" }}>
            <Box sx={{ position: "absolute", right: "20vw", top: "10vh" }}>
                <System morePlanets={true} />
            </Box>
            <Box sx={{ width: "100%", maxWidth: "1200px", ml: "50%", transform: "translateX(-50%)", mt: { xs: 4, md: 20 }, p: 4 }}>
                <SectionTitle textAlign="left">
                    О Нас
                </SectionTitle>
                <Typography sx={{ width: { sx: "auto", md: "50%" }, maxWidth: "600px", fontSize: { xs: 16, sm: 20 }, mt: { xs: 4, md: 10 } }}>
                    Мы - команда профессионалов, увлеченных технологиями и
                    креативными идеями. Наша цель - изменить мир с помощью
                    инноваций и смелых решений.
                </Typography>
                <Typography sx={{ width: { sx: "auto", md: "50%" }, maxWidth: "600px", fontSize: { xs: 16, sm: 20 }, mt: { xs: 2, md: 4 } }}>
                    Мы не боимся сложных задач и всегда готовы к вызовам.
                    Наша страсть к технологиям исключает слово 'невозможно'
                    из нашего словаря.
                </Typography>
                <Typography sx={{ width: { sx: "auto", md: "50%" }, maxWidth: "600px", fontSize: { xs: 16, sm: 20 }, mt: { xs: 2, md: 4 } }}>
                    Присоединяйтесь к нам, и вместе мы сделаем невозможное
                    возможным!
                </Typography>
            </Box>
        </SectionWrap>
    )
}

export default AboutUs