import mlm1 from "../../../../../assets/img/portfolioProjectsImg/mlm1.png"
import mlm2 from "../../../../../assets/img/portfolioProjectsImg/mlm2.png"

export const MlmFrames = [{
        id: 1,
        title: "Платформа",
        text: "Здесь можно инвестировать в различные проекты, следить за событиями проектов",
        listTitle: "Дополнительно",
        list: [
            "удобная в пользовании платформа",
            "административная панель управления данными",
            "красивый список проектов",
        ],
        img: mlm1
    },
    {
        id: 2,
        title: "Магазин",
        text: "Здесь можно купить продукцию магазина",
        listTitle: "Дополнительно",
        list: [
            "интуитивно понятный интерфейс",
            "карточки товаров",
            "карусель событий",
        ],
        img: mlm2
    },
]