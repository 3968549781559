import React from "react";
import "./styles/System.css"
import "./styles/OrbitZero.css"
import "./styles/OrbitOne.css"
import "./styles/OrbitTwo.css"
import { Box } from "@mui/material";

const System = ({
    mode = "dark",
    invisible = false,
    morePlanets = false
}) => {

    return (
        <Box className={"circle system-wrap" + (invisible ? " invisible" : "")} sx={{ height: "100vh" }}>
            {/* <div className={`circle filled-${mode} star`} /> */}
            <Box className={`circle filled-${mode}`} sx={{ height: "20vw", aspectRatio: "1 / 1", position: "absolute", transform: "translate(50%, -50%)", left: "-50%" }} />
            <div className={`circle orbit-${mode} orbit-0`}>
                <div className={`circle filled-${mode} mercury`} />
            </div>
            <div className={`circle orbit-${mode} orbit-1`}>
                <div className={`circle venus-orb`}>
                    <div className={`circle filled-${mode} venus-sattelite`} />
                    <div className={`circle filled-${mode} venus`} />
                </div>
                {morePlanets && <div className={`circle filled-${mode} chronus`} />}
            </div>
            <div className={`circle orbit-${mode} orbit-2`}>
                <div className={`circle earth-orb`}>
                    <div className={`circle filled-${mode} earth-sattelite-0`} />
                    <div className={`circle filled-${mode} earth-sattelite-1`} />
                    <div className={`circle filled-${mode} earth`} />
                </div>
                {morePlanets && <div className={`circle filled-${mode} deamos`} />}
                {morePlanets && <div className={`circle filled-${mode} fobos`} />}
            </div>
        </Box>
    )
}

export default System;