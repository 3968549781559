import React from "react";
import { Box } from "@mui/material";

const Circle = ({ bordersQty = 1, rotation=0, size=100, sx }) => {
    const bdrSet = "1px solid"

    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: "100%",
                transform: `rotate(${rotation}deg)`,
                bordeColor: "primary.main",
                borderTop: bordersQty >= 1 ? bdrSet : "none",
                borderRight: bordersQty >= 2 ? bdrSet : "none",
                borderBottom: bordersQty >= 3 ? bdrSet : "none",
                borderLeft: bordersQty >= 4 ? bdrSet : "none",
                ...sx
            }} />
    )
}

export default Circle