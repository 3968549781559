import React from "react";
import RoundBtn from "../../../assets/UI/roundBtn/RoundBtn";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import PortfolioStore from "../store/portfolio.store";
import { useInstance } from "react-ioc";
import { observer } from "mobx-react";
import SpaceBtn from "../../../assets/UI/spaceBtn/SpaceBtn";

const Btns = observer(() => {
    const store = useInstance(PortfolioStore)
    const mainTheme = useTheme()
    const mobileBreakpoint = useMediaQuery(mainTheme.breakpoints.down("md"))

    const handleClick = (id) => {
        store.setActiveBtnId(store.getActiveBtnId() ? null : id)
    }

    return (
        <>
            {mobileBreakpoint && store.getActiveBtnId() &&
            <Box
            sx={{
                display: "flex", 
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 15,
                paddingBottom: 15
            }}
            >
                <SpaceBtn
                    title={""}
                    speed={1}
                    onClick={() => handleClick(null)}
                />
            </Box>  
            }
            <Box sx={{
                mt: mobileBreakpoint ? 20 : 8,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: store.getActiveBtnId() ? "auto" : "100%",
                maxWidth: { xs: "100%", lg: "1400px" },
                transform: store.getActiveBtnId() ? "translate(-50%, -50%)" : "translateX(-50%)",
                ml: store.getActiveBtnId() ? 0 : "50%",
                gap: mobileBreakpoint ? 20 : 4,
                p: 4,
                pb: mobileBreakpoint ? 20 : 4,
                transition: "1s",
                position: store.getActiveBtnId() && "absolute",
                top: "50%",
                left: 0
            }}>
                {store.getRotationCounted() === true && store.getBtns().map((btn, index) => {
                    if (btn.id === store.getActiveBtnId() || !store.getActiveBtnId()) {
                        return (
                            <Box key={btn.id}>
                                {mobileBreakpoint ?
                                    !store.getActiveBtnId() &&
                                    <SpaceBtn
                                        title={btn.id === store.getActiveBtnId() ? "" : btn.title}
                                        speed={btn.rotation || 1}
                                        onClick={() => handleClick(btn.id)}
                                    />
                                    :
                                    <RoundBtn
                                        type={btn.type}
                                        title={btn.id === store.getActiveBtnId() ? "" : btn.title}
                                        rotation={btn.rotation}
                                        speed={btn.speed}
                                        onClick={() => handleClick(btn.id)}
                                    />
                                }
                            </Box>
                        )
                    }
                })}
            </Box>
        </>
    )
})

export default Btns