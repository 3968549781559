import { useEffect } from 'react';
import './App.css';
import Main from './sections/main/Main';
import AboutUs from './sections/about_us/AboutUs';
import Footer from './sections/footer/Footer';
import Contacts from './sections/Contacts/Contacts';
import Portfolio from './sections/portfolio/Portfolio';

function App() {

  return (
    <div className="App">
      <Main/>
      <AboutUs/>
      <Portfolio/>
      <Contacts/>
      <Footer/>
    </div>
  );
}

export default App;
