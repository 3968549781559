import React from "react"
import { Typography } from "@mui/material";

const SectionTitle = ({children, textAlign="center", sx, props}) => {

    return (
        <Typography variant="h2" sx={{ fontSize: { xs: "3rem", sm: "5rem" }, textAlign: textAlign, ...sx }} {...props}>
            {children}
        </Typography>
    )
}

export default SectionTitle;