import { Typography } from "@mui/material";
import React from "react";
import { provider } from "react-ioc";
import Carusel from "../../../../assets/UI/carusel/Carusel";
import { crmFrames } from "./store/CrmData";

const Crm = provider()(() => {

    return (
        <>
            <Carusel frames={crmFrames} />
        </>
    )
})

export default Crm