import { Box } from "@mui/material"
import React from "react"
import "./styles/btnSkin.css"
import { transform } from "typescript"

const BtnSkin = ({speed}) => {

    const Circle = (additionStyles) => {
        return (
            <Box
                className={"btn-skin-animate"}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    borderRadius: "50%",
                    ...additionStyles,
                    borderColor: "primary.contrast",
                }}
            />
        )
    }

    return (
        <>
            {Circle({
                width: 200,
                height: 200,
                borderTop: "1px solid",
                animationDuration: speed+"s",
                animationName: "btn-skin-rotate"
                // borderLeft: "1px solid",
            })}
            {Circle({
                width: 180,
                height: 100,
                borderRight: "1px solid",
                animationDuration: speed+4+"s",
                animationName: "btn-skin-rotate"
                // borderLeft: "1px solid",
            })}
            {Circle({
                width: 160,
                height: 50,
                borderBottom: "1px solid",
                animationDuration: speed+1+"s",
                animationName: "btn-skin-rotate"
                // borderLeft: "1px solid",
            })}
            {Circle({
                width: 140,
                height: 40,
                borderLeft: "1px solid",
                animationDuration: speed+3+"s",
                animationName: "btn-skin-rotate"
                // borderLeft: "1px solid",
            })}
            {Circle({
                width: 30,
                height: 30,
                border: "1px solid",
                transform: "translate(-50%, -50%)",
                opacity: 0.2
            })}
        </>

    )
}

export default BtnSkin