import React from "react";
import { Box, Typography } from "@mui/material"
import formStore from "../../store/Form.store";
import { observer } from "mobx-react";
import CustomLink from "../../../../assets/UI/text/CustomLink";
import EmailLink from "../../../../assets/UI/text/EmailLink";

const ContactUs = observer(() => {

    return (
        <Box
            sx={{
                width: { xs: "100%", md: 400, lg: 500 },
                order: formStore.getSended() ? 0 : 2,
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: formStore.getSended() ? "end" : "start" },
            }}>
            <Typography variant="h3" sx={{ fontSize: "1.5rem", mt: 4, fontWeight: 300 }}>
                Или напиши нам:
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xs: "row", md: "column"},
                    gap: 2,
                    mt: {xs: 4, sm: 6},
                    alignItems: formStore.getSended() ? "end" : "start"
                }}>
                <CustomLink
                    href="https://t.me/deepleIt"
                    text="Telegram"
                    target="_blank"
                />
                <EmailLink />
            </Box>
        </Box>
    )
})

export default ContactUs