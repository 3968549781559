import React, { useEffect, useState } from "react";
import arrow from "../../img/icon/arrow.svg"
import "./styles/arrow.style.css"
import { Box } from "@mui/material";

const Arrow = ({ size = "40", direction = "left", animationSpeed = "1.6s" }) => {

    const rotation = () => {
        switch (direction) {
            case "top":
                return "rotate(270deg)"
            case "right":
                return "rotate(0deg)"
            case "bottom":
                return "rotate(90deg)"
            case "left":
                return "rotate(180deg)"
            default:
                return "rotate(0deg)"
        }
    }

    return (
        <Box sx={{transform: rotation(), width: `${size}px`,  height: `${size}px`}}>
            <img
                src={arrow}
                className="arrow"
                style={{
                    width: `${size}px`,
                    transition: "0.6s",
                    animationDuration: animationSpeed
                }}
            />
        </Box>
    )

}

export default Arrow