import { Box, Typography } from "@mui/material";
import React from "react";

const CaruselBtn = ({ color = "primary.main", title, active, onClick }) => {

    const handelClick = (e) => {
        onClick && onClick(e)
    }

    return (
        <Box
            onClick={handelClick}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                cursor: "pointer"
            }}
        >
            <Typography
            variant="h5"
                sx={{
                    transition: ".6s",
                    fontWeight: active ? 400 : 100,
                    color: color
                }}>
                {title}
            </Typography>
            <Box
                sx={{
                    border: "1px solid",
                    borderColor: color,
                    width: 10,
                    height: 10,
                    borderRadius: "100%",
                    backgroundColor: active ? color : "transparent",
                    transition: ".6s"
                }}
            />
        </Box>

    )
}

export default CaruselBtn