import React from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import RoundBtnStore from "../store/store";
import { useInstance } from "react-ioc";
import FilledCircle from "../../geometry/FilledCircle";

const SmallCircles = observer(() => {
    const store = useInstance(RoundBtnStore)

    const circlePosOne = () => {
        switch (store.getInitialRotation()) {
            case 0:
                return { top: "8%", left: "8%" }
            case 1:
                return { top: "8%", left: "80%" }
            case 2:
                return { top: "32%", left: "8%" }
            case 3:
                return { top: "80%", left: "80%" }
            default:
                return { top: "8%", left: "8%" }
        }
    }
    
    const circlePosTwo = () => {
        switch (store.getInitialRotation()) {
            case 0:
                return { top: "60%", left: "16%" }
            case 1:
                return { top: "28%", left: "2%" }
            case 2:
                return { top: "74%", left: "64%" }
            case 3:
                return { top: "0%", left: "0%" }
            default:
                return { top: "60%", left: "16%" }
        }
    }

    const circlePosThree = () => {
        switch (store.getInitialRotation()) {
            case 0:
                return { top: "72%", left: "4%" }
            case 1:
                return { top: "94%", left: "50%" }
            case 2:
                return { top: "74%", left: "4%" }
            case 3:
                return { top: "10%", left: "80%" }
            default:
                return { top: "72%", left: "4%" }
        }
    }

    const circlePosFour = () => {
        switch (store.getInitialRotation()) {
            case 0:
                return { top: "70%", left: "-2%" }
            case 1:
                return { top: "100%", left: "60%" }
            case 2:
                return { top: "104%", left: "56%" }
            case 3:
                return { top: "4%", left: "88%" }
            default:
                return { top: "70%", left: "-2%" }
        }
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                transition: store.getSpeed(),
                transform: store.getHover() ? "rotate(60deg)" : "rotate(0deg)",
                transitionTimingFunction: "ease-in-out"
            }}
        >
            <FilledCircle
                size={"16%"}
                bgcolor="primary.contrast005"
                sx={{
                    position: "absolute",
                    ...circlePosOne()
                }}
            />
            <FilledCircle
                size={"30%"}
                bgcolor="primary.contrast005"
                sx={{
                    position: "absolute",
                    ...circlePosTwo()
                }}
            />
            <FilledCircle
                size={"10%"}
                bgcolor="primary.contrast005"
                sx={{
                    position: "absolute",
                    ...circlePosThree()
                }}
            />
            <FilledCircle
                size={"6%"}
                bgcolor="primary.contrast005"
                sx={{
                    position: "absolute",
                    ...circlePosFour()
                }}
            />
        </Box>
    )
})

export default SmallCircles