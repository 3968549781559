import tableImg from "../../../../../assets/img/portfolioProjectsImg/srmTable.png"
import scheduleImg from "../../../../../assets/img/portfolioProjectsImg/srmScheduleImg.png"
import price from "../../../../../assets/img/portfolioProjectsImg/srmPrice.png"
import card from "../../../../../assets/img/portfolioProjectsImg/srmCard.png"

export const crmFrames = [{
        id: 1,
        title: "Табель",
        text: "Здесь можно составить график на день. Посмотреть место, время, должность сотрудника в этот день",
        listTitle: "Дополнительно",
        list: [
            "подсветка сотрудника при наведении",
            "просмотр любого месяца во время редактирования даты",
            "подсветка выбранной даты",
            "просмотр графика сотрудника со всех центров"
        ],
        img: tableImg
    },
    {
        id: 2,
        title: "Расписание",
        text: "Здесь можно записать клиента, отредактировать запись, видеть свое расписание на этот день.",
        listTitle: "Дополнительно",
        list: [
            "просмотр удаленных записей",
            "драгондроп записей (передвинуть запись на другое место, и она сама перепишется)",
            "подсказка при клике на запись"
        ],
        img: scheduleImg
    },
    {
        id: 3,
        title: "Прайс",
        text: "Здесь можно создавать и редактировать группы и услуги, устанавливать цены и другие параметры. Можно делать выборку по центру и пользователю.",
        listTitle: "Дополнительно",
        list: [
            "вложенность любой глубины",
            "настраиваемая сортировка",
            "поиск услуги",
            "быстрая отрисовка всех элементов (все группы открыты)",
        ],
        img: price
    },
    {
        id: 4,
        title: "Карта",
        text: "Здесь можно управлять информацией о клиенте и его событиях (смс, звонками, записями, приемами)",
        listTitle: "Дополнительно",
        list: [
            "фильтры для поиска нужных элементов",
            "редактирование элементов из любой точки карты",
            "кастомная виртуализация списка событий",
            "галлерея фотографий",
            "скачивание фото архивом",
        ],
        img: card
    },
    // {
    //     id: 5,
    //     title: "Бэкенд",
    // }
]