import React, { useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { useInstance } from "react-ioc";
import CaruselStore from "../store/Carusel.store";
import { observer } from "mobx-react";
import CaruselBtn from "../../CaruselBtn/CaruselBtn";

const Navigation = observer(() => {
    const store = useInstance(CaruselStore)

    const handleSectionClick = (index) => {
        store.setActiveFrameIndex(index)
    }


    return (
        <Box 
        sx={{
            display: "flex", 
            justifyContent: "center", 
            gap: 5,
            mt: 2
            }}>
            {store.getFrames()?.length > 0 && store.getFrames().map((item, index) => {
                return (
                    <Box key={item?.id || index}>
                        <CaruselBtn onClick={()=>handleSectionClick(index)} color="primary.contrast" title={item?.title} active={store.getActiveFrameIndex()==index}/>
                    </Box>
                )
            })}

        </Box>
    )
})

export default Navigation