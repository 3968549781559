import React from "react";
import { contactsId } from "../../constants/sectionIds";
import { Box, useMediaQuery, useTheme } from "@mui/material"
import SendForm from "./components/form/SendForm";
import { observer } from "mobx-react";
import ContactUs from "./components/contact_us/ContactUs";
import SpiralDivider from "../../assets/UI/geometry/SpiralDivider";
import BottomImg from "./components/BottomImg";
import SectionTitle from "../../assets/UI/text/SectionTitle";
import SectionWrap from "../../assets/UI/SectionWrap";

const Contacts = observer(() => {

    const mainTheme = useTheme()
    const mobileBreakpoint = useMediaQuery(mainTheme.breakpoints.down("md"))

    return (
        <SectionWrap sx={{ pb: 4 }} sectionId={contactsId}>
            <SectionTitle>
                Контакты
            </SectionTitle>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: { xs: "start", md: "center" },
                    flexDirection: {xs: "column", md: "row"},
                    minHeight: { xs: "auto", md: 400 },
                    alignItems: "center",
                    gap: 4,
                    mt: 6,
                    flexWrap: "wrap",
                    position: "relative",
                    pb: 20
                }}>
                <SendForm />
                {!mobileBreakpoint &&
                    <SpiralDivider sx={{ order: 1 }} />
                }
                <ContactUs />
                <BottomImg />
            </Box>
        </SectionWrap>
    )
})

export default Contacts