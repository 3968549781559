import landingVideo from "../../../../../assets/img/portfolioProjectsImg/landingVideo.png"
import landingCards from "../../../../../assets/img/portfolioProjectsImg/m2spanishCards.jpg"
import landingMap from "../../../../../assets/img/portfolioProjectsImg/landingMap.png"

export const landingFrames = [
    {
        id: 1,
        title: "Видеоплеер",
        text: "Здесь можно посмотреть курс, его видеоуроки и информацию. Так же его можно купить",
        listTitle: "Дополнительно",
        list: [
            "видеоплеер",
            "адаптация под мобильную версию",
            "кросбраузерная поддержка видео",
            "покупка урока"
        ],
        img: landingVideo
    },
    {
        id: 2,
        title: "Интерактивная карта",
        text: "Здесь можно узнать о предприятиях и производимой продукции продукции",
        listTitle: "Дополнительно",
        list: [
            "интерактивная карта",
            "удобные фильтры",
            "установка границ карты",
            "занесение данных через удобную административную панель",
        ],
        img: landingMap
    },
]