import { Box, Link } from "@mui/material";
import React from "react";
import { sectionsRu } from "../../../constants/sectionIds";
import CustomLink from "../../../assets/UI/text/CustomLink";

const SectionLinks = () => {

    const SectionLink = (title, id) => {
        const clickLink = (e) => {
            e.preventDefault()
            const targetElement = document.getElementById(id)
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
        return (
            <CustomLink href={`#${id}`} onClick={clickLink} color="inherit" text={title}/>
        )
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {sectionsRu?.map((section) => {
                return (
                    <Box key={section.id}>
                        {SectionLink(section.title, section.id)}
                    </Box>
                )
            })}
        </Box>
    )
}

export default SectionLinks;