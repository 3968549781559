import { Typography } from "@mui/material";
import React from "react";
import { provider } from "react-ioc";
import Carusel from "../../../../assets/UI/carusel/Carusel";
import { MlmFrames } from "./store/Mlm";

const Mlm = provider()(() => {

    return (
        <>
            <Carusel frames={MlmFrames} />
        </>
    )
})

export default Mlm