import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import "./styles/MainStyles.css";
import System from "../../assets/CustomAnimation/System/System";
import { Box, Typography } from "@mui/material";
import { mainId } from "../../constants/sectionIds";

const Main = observer(() => {

    useEffect(() => {
        setTimeout(() => {
            const title = document.getElementById("h1-DEEPLE")
            title.classList.add("title-gradient")
        }, 3000)
    }, [])


    return (
        <div className="main" id={mainId}>
            <Typography id={"h1-DEEPLE"} className="title" sx={{ fontSize: { xs: "4rem", md: "10rem" }, fontWeight: 100 }}>
                DEEPLEE
            </Typography>
            <Box sx={{ position: "absolute", top: "-2vh", left: 0 }}>
                <System mode="light" invisible={true} morePlanets={false} />
            </Box>
        </div>
    )
})

export default Main