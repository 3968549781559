import React from "react";
import { provider, useInstance } from "react-ioc";
import PortfolioStore from "../store/portfolio.store";
import Crm from "./crm_project/Crm";
import Landing from "./landing_project/Landing";
import Mlm from "./mlm_project/Mlm";
import { observer } from "mobx-react";
import { project1Id, project2Id, project3Id } from "../store/projects"
import { Box } from "@mui/material";


const PortfolioDetails = observer(() => {
    const portfolioStore = useInstance(PortfolioStore)

    if (!portfolioStore.getActiveBtnId()) {
        return (<></>)
    }

    const portfolioProjectWrap = (project) => {
        return (
            <Box sx={{
                width: { xs: "100%", md: "calc(100%-300px)" },
                maxWidth: "1200px",
                ml: "50%",
                transform: "translateX(-50%)",
                display: "inline-block"
            }}>
                {project}
            </Box>
        )
    }

    const portfolioElement = () => {
        switch (portfolioStore.getActiveBtnId()) {
            case project1Id:
                return portfolioProjectWrap(<Crm />)
            case project2Id:
                return portfolioProjectWrap(<Landing />)
            case project3Id:
                return portfolioProjectWrap(<Mlm />)
            default: return <></>
        }
    }

    return (
        <>
            {portfolioElement()}
        </>
    )
})

export default PortfolioDetails