import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import CaruselStore from "../store/Carusel.store";
import { useInstance } from "react-ioc";
import { observer } from "mobx-react";

const ImgComponent = observer(() => {
    const store = useInstance(CaruselStore)
    const [appear, setAppear] = useState(false)
    const [visible, setVisible] = useState(false)
    const [frameData, setFrameData] = useState(null)
    const changeTime = 600

    const changeAppear = () => {
        setAppear(false)
        setTimeout(() => {
            setAppear(true)
        }, changeTime)
    }

    const changeVisible = () => {
        setVisible(false)
        setTimeout(() => {
            setVisible(true)
        }, changeTime / 2)
    }

    useEffect(() => {
        changeAppear()
        setFrameData(store.getActiveFrame())
        changeVisible()
    }, [store.getActiveFrameIndex()])

    useEffect(() => {
        changeAppear()
    }, [store.getActiveFrame()])

    return (
        <img
            style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "20px",
                transition: `.${changeTime}s`,
                transform: `translateX(${appear ? 0 : 200}px)`,
                opacity: appear ? 1 : 0,
                display: visible ? "block" : "none",
            }}
            src={store.getActiveFrame()?.img} alt={store.getActiveFrame()?.title} />
    )
})

export default ImgComponent