import React from "react";
import { TextField } from "@mui/material";
import formStore from "../../../store/Form.store";
import { observer } from "mobx-react-lite";

const NameInput = observer(({sx}) => {

    const handleChangeName = (text) => {
        formStore.setNameText(text)
        formStore.setNameError(false)
    }

    return (
            <TextField
                    variant="standard"
                    size="small"
                    label="Ваше имя"
                    required
                    fullWidth
                    error={formStore.getNameError()}
                    sx={{
                        maxWidth: { xs: "auto", md: 300 },
                        minWidth: 200,
                        ...sx
                    }}
                    onChange={(e) => handleChangeName(e.target.value)}
                />
    )
})

export default NameInput