import React from "react";
import { Box, Typography } from "@mui/material";
import cube from "../../../img/geometry/cube.svg"
import sphere from "../../../img/geometry/sphere.svg"
import pyramid from "../../../img/geometry/pyramid.svg"
import { observer } from "mobx-react";
import RoundBtnStore from "../store/store";
import { useInstance } from "react-ioc";

const BtnContent = observer(() => {
    const store = useInstance(RoundBtnStore)

    const switchIcon = () => {
        switch (store.getType()) {
            case "cube":
                return cube
            case "sphere":
                return sphere
            case "pyramid":
                return pyramid
            default:
                return cube
        }
    }

    return (
        <>
            <Box
                sx={{
                    transition: `opacity ${store.getSpeed()}`,
                    opacity: store.getHover() ? 0 : 1,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%"
                }}
            >
                <img
                    src={switchIcon()}
                    alt={store.getType()}
                    style={{ width: "50%" }}
                />
            </Box>
            <Box
                sx={{
                    transition: `opacity ${store.getSpeed()}`,
                    opacity: store.getHover() ? 1 : 0,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Typography
                    sx={{
                        color: "primary.contrast",
                        textAlign: "right",
                        fontSize: 30,
                        fontWeight: 100
                    }}>
                    {store.getTitle()}
                </Typography>
            </Box>
        </>
    )
})

export default BtnContent