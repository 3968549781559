import {makeAutoObservable} from "mobx";

class FormStore {

    contact = {
        text: "",
        error: false
    }

    name = {
        text: "",
        error: false
    }

    text = {
        text: "",
        error: false
    }

    checked = false

    sended = false

    //get /set texts

    getContactText() {
        return this.contact.text
    }

    setContactText(text) {
        this.contact.text = text
    }

    getNameText() {
        return this.name.text
    }

    setNameText(text) {
        this.name.text = text
    }

    getTextText() {
        return this.text.text
    }

    setTextText(text) {
        this.text.text = text
    }

    //error get/set

    getContactError() {
        return this.contact.error
    }

    setContactError(error) {
        this.contact.error = error
    }

    getNameError() {
        return this.name.error
    }

    setNameError(error) {
        this.name.error = error
    }

    getTextError() {
        return this.text.error
    }

    setTextError(error) {
        this.text.error = error
    }

    // cheked send get/set

    getChecked() {
        return this.checked
    }

    setChecked(checked) {
        this.checked = checked
    }

    switchChecked() {
        this.setChecked(!this.checked)
    }
    
    getSended() {
        return this.sended
    }

    setSended(sended) {
        this.sended = sended
    }

    //count send btn name /disabled

    getDisableBtn() {
        if (this.sended || !this.checked || !this.contact.text?.length>0 || !this.name.text?.length>0 || !this.text.text?.length>0){
            return true
        }else return false
    }

    getSendBtnName() {
        if (this.sended){
            return "Ваше сообщение отправлено"
        }else{
            if (this.checked){
                if (!this.contact.text?.length>0){
                    return "Заполните ваш телефон или email"
                }else if (!this.name.text?.length>0){
                    return "Заполните ваше имя"
                }else if (!this.text.text?.length>0){
                    return "Заполните описание проекта"
                }else{
                    return "Отправить"
                }
            }else{
                return "Подтвердите ваше согласие с политикой конфиденциальности"
            }
        }
    }

    //send funct 

    send() {
        return new Promise((resolve)=>{
            if (!this.contact.text?.length>0){
                this.setContactError(true)
                resolve(false)
            }
            if (!this.name.text?.length>0){
                this.setNameError(true)
                resolve(false)
            }
            if (!this.text.text?.length>0){
                this.setTextError(true)
                resolve(false)
            }
            resolve("sended")
        })
    }

    constructor() {
        makeAutoObservable(this)
      }
}

const formStore = new FormStore()
export default formStore