import React from "react";
import { Box } from "@mui/material"
import Circle from "../../../assets/UI/geometry/Circle";

const BottomImg = () => {

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%",
                bottom: 0
            }}>
            <Box sx={{ borderRadius: "100%", bgcolor: "primary.main", width: 60, height: 60 }} />
            <Circle rotation={180} size={200} sx={{ mt: -16 }} />
        </Box>
    )
}

export default BottomImg