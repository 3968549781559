import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, List, ListItem, ListItemIcon } from "@mui/material";
import listSign from "../../img/icon/listItem.svg"
import SpaceListItem from "./components/SpaceListItem";

const SpaceList = ({ list }) => {

    return (
        <List>
            {list?.length > 0 && list?.map((item, index) => {
                return (
                    <SpaceListItem key={index} title={item} index={index}/>
                )
            })}
        </List>
    )
}

export default SpaceList