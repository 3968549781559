export const mainId = "main"
export const aboutUsId = "about-us"
export const portfolioId = "portfolio"
export const contactsId = "contacts"

export const sectionsRu = [
    {
        title: "В начало",
        id: mainId,
    },
    {
        title: "О нас",
        id: aboutUsId,
    },
    {
        title: "Наши проекты",
        id: portfolioId,
    },
    {
        title: "Контакты",
        id: contactsId,
    },
]