import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, List, ListItem, ListItemIcon } from "@mui/material";
import { useInstance } from "react-ioc";
import CaruselStore from "../store/Carusel.store";
import { observer } from "mobx-react";
import SpaceList from "../../list/SpaceList";

const TextComponent = observer(({ showTitle = true }) => {
    const store = useInstance(CaruselStore)
    const [appear, setAppear] = useState(false)
    const [visible, setVisible] = useState(false)
    const [frameData, setFrameData] = useState(null)
    const changeTime = 600

    const changeAppear = () => {
        setAppear(false)
        setFrameData(store.getActiveFrame())
        setTimeout(() => {
            setAppear(true)
        }, changeTime)
    }

    const changeVisible = () => {
        setVisible(false)
        setTimeout(() => {
            setVisible(true)
        }, changeTime / 2)
    }

    useEffect(() => {
        changeAppear()
        changeVisible()
    }, [store.getActiveFrameIndex()])

    useEffect(() => {
        changeAppear()
    }, [store.getActiveFrame()])

    return (
        <Box
            sx={{
                transition: `.${changeTime}s`,
                transform: `translateX(${appear ? 0 : -200}px)`,
                opacity: appear ? 1 : 0,
                display: visible ? "block" : "none",
            }}
        >
            {showTitle &&
                <Typography sx={{ color: "white", fontWeight: 400 }} variant="h2">
                    {frameData?.title}
                </Typography>
            }
            <Typography sx={{ color: "white" }}>
                {frameData?.text}
            </Typography>
            {frameData?.list &&
                <>
                    <Typography sx={{ color: "white", mt: 4 }} variant="h4">
                        {frameData?.listTitle}
                    </Typography>
                    <SpaceList list={frameData?.list} />
                </>
            }

        </Box>
    )
})

export default TextComponent