import React, { useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { provider } from "react-ioc";
import { useInstance } from "react-ioc";
import CaruselStore from "./store/Carusel.store";
import { observer } from "mobx-react";
import Frame from "./components/Frame";
import Navigation from "./components/Navigation";
import FrameWithNav from "./components/FrameWithNav";

const Carusel = provider(CaruselStore)(observer(({ frames = [] }) => {
    const store = useInstance(CaruselStore)
    const mainTheme = useTheme()
    const mobileBreakpoint = useMediaQuery(mainTheme.breakpoints.down("md"))

    useEffect(() => {
        store.setFrames(frames)
    }, [])

    return (
        <Box sx={{ mt: 10, mb: 10, pl: 4, pr: 4 }}>
            {mobileBreakpoint ?
                <FrameWithNav />
                :
                <>
                    <Frame />
                    <Navigation />
                </>
            }
        </Box>
    )
}))

export default Carusel